<template>
  <div class="app-content-import d-flex flex-column mt-5">

    <!-- Content folder selection -->
    <b-btn class="align-self-center"
           :disabled="pendingImport"
           variant="outline-primary"
           @click="onSelectContentFolder">Select content folder</b-btn>

    <!-- Content import status -->
    <div class="d-flex justify-content-center align-items-center mt-5">

      <!-- Loading -->
      <template v-if="pendingImport">
        <b-spinner type="grow" variant="primary" small />
        <span class="ml-3">Importing content.. this may take a while.</span>
      </template>

      <!-- Completed -->
      <template v-if="!pendingImport && importResults">
        <font-awesome-icon icon="check" />
        <span class="ml-3">Content imported</span>
      </template>
    </div>

  </div>
</template>

<script>
import { remote } from 'electron';

export default {

  data() {
    return {
      pendingImport: false,
      importResults: null
    }
  },

  created () {
    this._rerouteIfNotOffline();
  },

  methods: {

    async onSelectContentFolder() {
      try {
        const results = await remote.dialog.showOpenDialog({ properties: ['openDirectory'] });
        if (results.canceled) { return; }
        await this.onContentImport(results.filePaths[0]);
      } catch (e) {
        console.log(e);
      }

    },

    async onContentImport(path) {
      try {
        this.pendingImport = true;
        this.importResults = await this.$store.dispatch('importLocalContent', path);
        await this.$store.dispatch('loadUserContent');
        await this.$store.dispatch('loadUserFiles');
        await this.$store.dispatch('app/changeNavigationHeaderKey', (this.$store.getters['app/navigationHeaderKey'] + 1))
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      } finally {
        this.pendingImport = false;
      }
    },

    _rerouteIfNotOffline() {
      if (this.$store.getters['app/isOffline']) { return }
      this.$router.replace({ name: 'App:Main'});
    }

  }
}
</script>
